//#region react import
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
//#end region

//#region component import
import GstaModalConfirmation from "../GstaModalConfirmation/GstaModalConfirmation.component";
//#end region

//#region services import
import { logoutFromApi } from "../../Services/AuthenticateService";
//#endregion

//#region constants import
import { YES, NO } from "../GstaModal/GstaModal.constants";
import { logOut } from "../../Helper/logInLogOut";
import { getTranslations } from "../../Helper/TranslationController";
import { DisableOverflow, EnableOverflow } from "../../Helper/Overflow";
//#endregion

//#region style import
import "./LogoutButton.style.scss";
import { FIREFOX_VERSIONS } from "../../Constants/BrowserConstants";
//#endregion

const LogoutButton = () => {
  const [openModal, setOpenModal] = useState(false);
  const translations = useSelector((state) => state.translationSlice.translations);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  /** Reset all the state */
  const handleValidateModal = async () => {
    await logoutFromApi();
    setOpenModal(false);
    logOut(dispatch, navigate);
    EnableOverflow();
  };

  const handleOpenModal = () => {
    setOpenModal(true);
    DisableOverflow();
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    EnableOverflow();
  };

  return (
    <>
      <button
        className="logout-button"
        onClick={handleOpenModal}
        data-testid="open-logout-modal"
      >
        <i className="icon-shutdown navigation-button-icon" />
        <span>{getTranslations("navigation_disconnection", translations)}</span>
      </button>
      <GstaModalConfirmation
        modalOpen={openModal}
        handleCloseModal={handleCloseModal}
        handleValidate={handleValidateModal}
        title={getTranslations("navigation_disconnection", translations)}
        messages={[getTranslations("navigation_disconnection_message", translations)]}
        cancelText={getTranslations(NO, translations)}
        validText={getTranslations(YES, translations)}
        overrideStyle={new RegExp(FIREFOX_VERSIONS).test(navigator.userAgent) ? { overflow: "hidden", scrollbarWidth: "none" } : {}}
      />
    </>
  );
};

export default LogoutButton;
