//#region react import
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
//#endregion

//#region component import
import AcreosCheckBox from "../../../Components/AcreosCheckBox/AcreosCheckBox.component";
import AddTrainingModal from "../../Training/AddTrainingModal/AddTrainingModal.component";
import MainInformationForm from "../MainInformationForm/MainInformationForm.component";
import TrainingTable from "../TrainingTable/TrainingTable.component";
//#endregion

//#region store import
import { setErrorNotification, setSuccessNotification } from "../../../ReduxStore/notificationSlice";
import { addStudent } from "../../../ReduxStore/studentsSlice";
//#endregion

//#region service import
import { createStudent } from "../../../Services/StudentService";
//#endregion

//#region functions import
import { SetDataTestId } from "../../../Helper/DataTestId";
import { DateTimeToIsoString } from "../../../Helper/TimeConverter";
import { getTranslations } from "../../../Helper/TranslationController";
//#endregion

//#region constants import
import { CREATE_STUDENT } from "../../../Constants/AddtrainingModalType";
import { CUSTOM_FIELD_DATETIME } from "../../../Constants/CustomFieldType";
import { MANAGE_STUDENTS_ACCESS_WITH_DATES } from "../../../Constants/SettingsConstants";
import { STANDARD_FORMAT_STRING_MAX_LENGTH } from "../../../Constants/StringConstants";
import { STUDENTS } from "../../../Routing/PageNames";
import { defaultPassword } from "./CreateStudent.constants";
import { ICON_CHECK, ICON_UNDO } from "../../../Constants/IconConstants";
import { TRANSLATIONS } from "../../../Constants/Translations";
import { API_ERROR } from "../../../Constants/ErrorMessage";
//#endregion

//#region style import
import "./CreateStudent.style.scss";
//#endregion

const CreateStudent = () => {
  //#region useState
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState(defaultPassword);
  const [startDateTime, setStartSelectedDateTime] = useState();
  const [endDateTime, setEndSelectedDateTime] = useState();
  const [customFields, setCustomFields] = useState({});
  const [edditionMode, setEdditionMode] = useState(true);
  const [selectedTrainingForCreation, setSelectedTrainingsForCreation] = useState([]);
  const [previousSelectedTraining, setPreviousSelectedTraining] = useState([]);
  const [modalopen, setModalOpen] = useState(false);
  const [validateAndCreateNewStudent, setValidateAndCreateNewStudent] = useState(false);
  const [scrollTarget, setScrollTarget] = useState();
  //#endregion

  //#region other use
  const navigate = useNavigate();
  //#endregion

  //#region store
  const trainer = useSelector((state) => state.connexionSlice.trainer);
  const trainings = useSelector((state) => state.trainingSlice.trainings);
  const translations = useSelector((state) => state.translationSlice.translations);
  const customFieldDefinitions = useSelector((state) => state.studentSlice.customFields);
  const settingDictionary = useSelector((state) => state.settingSlice.settingDictionary);
  const dispatch = useDispatch();
  //#endregion

  //#region functions
  const checkValidRequiredField = (value, field) => {
    if (value && value.trim().length > 2) return true;

    scrollTargetTemp = { id: field };
    return false;
  };

  const checkValidRequiredFieldLength = (value, field) => {
    if (value && value.trim().length > 2 && value.length < STANDARD_FORMAT_STRING_MAX_LENGTH) return true;

    scrollTargetTemp = { id: field };
    return false;
  };

  const checkValidRequiredDate = (value, field) => {
    if (Date.parse(value)) {
      return true;
    }
    scrollTargetTemp = { id: field };
    return false;
  };

  const checkCustomFieldValid = (customFieldDefinition) => {
    return !customFieldDefinition.isMandatory || customFields[customFieldDefinition.name]?.value;
  };

  const handleSetLastName = (name) => {
    setLastName(name.toUpperCase());
  };

  const checkCustomFieldValue = () => {
    let customFieldValid = true;
    customFieldDefinitions?.forEach((customFieldDefinition) => {
      customFieldValid =
        customFieldValid &&
        (!customFieldDefinition.isMandatory ||
          (customFields[customFieldDefinition.name] &&
            customFields[customFieldDefinition.name].value.toString().length > 0));

      if (!customFieldValid && customFieldDefinition.isMandatory) {
        scrollTargetTemp = { id: customFieldDefinition.name };
      }
    });
    return customFieldValid;
  };

  let scrollTargetTemp = "";

  const getDatasToSendToApi = () => {
    scrollTargetTemp = scrollTarget?.id;

    let allPropertiesAreValid =
      checkValidRequiredFieldLength(firstName, "firstName") &&
      checkValidRequiredFieldLength(lastName, "lastName") &&
      checkValidRequiredField(password, "password") &&
      password.length === 4 &&
      checkCustomFieldValue();
    if (allPropertiesAreValid && settingDictionary[MANAGE_STUDENTS_ACCESS_WITH_DATES] === "true") {
      allPropertiesAreValid =
        allPropertiesAreValid &&
        checkValidRequiredDate(endDateTime, "dates") &&
        checkValidRequiredDate(startDateTime, "dates");
    }
    if (!allPropertiesAreValid) {
      setScrollTarget(scrollTargetTemp);
    }

    const objectToSentoApi = {
      firstName,
      lastName,
      password,
      authorId: trainer?.id,
      trainingsToSubscribe: selectedTrainingForCreation,
    };
    if (settingDictionary[MANAGE_STUDENTS_ACCESS_WITH_DATES] === "true") {
      objectToSentoApi.accessEnd = DateTimeToIsoString(endDateTime);
      objectToSentoApi.accessStart = DateTimeToIsoString(startDateTime);
    }

    const activeCenterId = trainer?.activeCenter?.id;
    const customFieldsCopy = { ...customFields };
    const customFieldValues = Object.entries(customFieldsCopy).map(([key, value]) => {
      if (value.fieldType === CUSTOM_FIELD_DATETIME.value) {
        let newValue = { ...value };
        newValue.value = DateTimeToIsoString(value.value);
        return newValue;
      }
      return value;
    });
    objectToSentoApi.customValues = customFieldValues;

    return { allPropertiesAreValid, objectToSentoApi, activeCenterId, customFieldValues };
  };

  const handleCreateStudent = async () => {
    const { allPropertiesAreValid, objectToSentoApi, activeCenterId, customFieldValues } = getDatasToSendToApi();
    if (allPropertiesAreValid) {
      try {
        const studentcreated = (await createStudent(activeCenterId, objectToSentoApi)).data;
        let machines = [];
        trainings.forEach((training) => {
          if (
            selectedTrainingForCreation.includes(training.id) &&
            !machines.find((machine) => machine.id === training.machineId)
          ) {
            machines.push({
              id: training.machineId,
              code: training.machineCode,
              languageKey: training.machineName,
            });
          }
        });
        const studentForListStudents = {
          id: studentcreated.user.id,
          guid: studentcreated.user.guid,
          name: lastName,
          firstName: firstName,
          lastConnectionDate: null,
          lastActivityDate: null,
          machineSubscribes: machines,
          accessEnd: DateTimeToIsoString(endDateTime),
          accessStart: DateTimeToIsoString(startDateTime),
          isActive: true,
          pinCode: password,
          customValues: customFieldValues,
        };
        dispatch(addStudent(studentForListStudents));
        dispatch(
          setSuccessNotification(
            `${getTranslations(TRANSLATIONS.STUDENT_CREATE_STUDENT, translations)} ${firstName} ${lastName} ${getTranslations(
              TRANSLATIONS.NOTIFICATION_ADD_STUDENT,
              translations
            )}`
          )
        );
        return true;
      } catch (e) {
        dispatch(setErrorNotification({
          message: `${firstName} ${lastName} ${getTranslations(
            API_ERROR.ERROR_API_USER_ALREADY_EXIST,
            translations
          )}`,
        }));
      }
    } else {
      setEdditionMode(false);
    }
    return false;
  };

  const initState = () => {
    setFirstName("");
    setLastName("");
    setPassword(defaultPassword);
    setEdditionMode(true);
    setSelectedTrainingsForCreation([]);
    setCustomFields({});
  };

  const deleteJourney = (id) => {
    const newSelectedJourney = selectedTrainingForCreation.filter((journeyId) => journeyId !== id);
    setSelectedTrainingsForCreation(newSelectedJourney);
  };

  const handleCancelClick = () => {
    navigate(STUDENTS.url);
  };

  const validateSelection = () => {
    setPreviousSelectedTraining([...selectedTrainingForCreation]);
  };

  const resetSelectedTraining = () => {
    setSelectedTrainingsForCreation(previousSelectedTraining);
  };

  const handleValidateClick = async () => {
    const creationProcess = await handleCreateStudent();
    if (creationProcess) {
      handleCancelClick();
    }
  };

  const handleValidateAndCreateNewStudent = async () => {
    const creationProcess = await handleCreateStudent();
    if (creationProcess) {
      initState();
    }
  };

  const handleValidateAndCreateNewStudentState = () => {
    setValidateAndCreateNewStudent(!validateAndCreateNewStudent);
  };

  const validateCreation = async () => {
    validateAndCreateNewStudent ? await handleValidateAndCreateNewStudent() : await handleValidateClick();
  };
  //#endregion

  return (
    <div>
      {modalopen && (
        <AddTrainingModal
          modalOpen={modalopen}
          setModalOpen={setModalOpen}
          type={CREATE_STUDENT}
          selectedTrainingToSubscribe={selectedTrainingForCreation}
          setSelectedTrainingToSubscribe={setSelectedTrainingsForCreation}
          cancelAction={resetSelectedTraining}
          validateAction={validateSelection}
          title={`${getTranslations(
            TRANSLATIONS.ADD_TRAINING_MODAL_STUDENT_TRAINING_INSCRIPTION,
            translations
          )} ${firstName} ${lastName}`}
        />
      )}
      <div className="bottom-margin display-flex-column">
        <form
          onSubmit={async (e) => {
            e.preventDefault();
            await validateCreation();
            e.target.reset();
          }}
        >
          <MainInformationForm
            firstName={firstName}
            setFirstName={setFirstName}
            lastName={lastName}
            setLastName={handleSetLastName}
            password={password}
            setPassword={setPassword}
            selectedStartDate={startDateTime}
            setStartSelectedDateTime={setStartSelectedDateTime}
            selectedEndDate={endDateTime}
            setEndSelectedDateTime={setEndSelectedDateTime}
            edditionMode={edditionMode}
            checkValidRequiredField={checkValidRequiredField}
            checkValidRequiredFieldLength={checkValidRequiredFieldLength}
            customFields={customFields}
            setCustomFields={setCustomFields}
            isValidRequiredDate={checkValidRequiredDate}
            checkCustomFieldValid={checkCustomFieldValid}
            scrollTarget={scrollTarget}
            manageWithDates={settingDictionary[MANAGE_STUDENTS_ACCESS_WITH_DATES] === "true"}
          />
          <TrainingTable
            setModalOpen={setModalOpen}
            deleteJourney={deleteJourney}
            trainingsIds={selectedTrainingForCreation}
          />
          <div className="bottom-fixed create-student-footer">
            <button
              className="gsta-button-outlined"
              onClick={handleCancelClick}
              data-testid={SetDataTestId("cancel-student-creation")}
              type="button"
            >
              <i className={ICON_UNDO} /> <span>{getTranslations(TRANSLATIONS.COMMON_CANCEL, translations)}</span>
            </button>

            <div className="double-button-container">
              <AcreosCheckBox
                dataTestId={SetDataTestId("check-box-create-student")}
                checked={validateAndCreateNewStudent}
                onChange={handleValidateAndCreateNewStudentState}
              />
              <span
                className="double-button-container_label"
                add-another-student={validateAndCreateNewStudent.toString()}
              >
                {getTranslations(TRANSLATIONS.STUDENT_CREATE_STUDENT_VALIDATE_AND_CREATE_STUDENT, translations)}
              </span>
              <button
                className="gsta-button-outlined"
                data-testid={SetDataTestId("validate-button-create-student")}
                type="submit"
              >
                <span>{getTranslations(TRANSLATIONS.COMMON_VALIDATE, translations)}</span>
                <i className={ICON_CHECK} />
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateStudent;
