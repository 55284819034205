//#region react import
import { useState } from "react";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
//#endregion

//#region functions import
import { SetDataTestId } from "../../Helper/DataTestId";
import { getTranslations } from "../../Helper/TranslationController";
//#endregion

//#region components import
import AcreosSelect from "../AcreosSelect/AcreosSelect.component";
import CreateValueFooter from "../CreateValueFooter/CreateValueFooter.component";
import GstaInput from "../GstaInput/GstaInput.component";
//#endregion

//#region services import
import { setErrorNotification } from "../../ReduxStore/notificationSlice";
import { getMachines } from "../../Services/MachineService";
import { getTrainingToDuplicate } from "../../Services/TrainingService";
//#endregion

//#region constants import
import { CREATE_CUSTOM_FIELD_NAME_MAX_LENGTH } from "../../Constants/StringConstants";
import { TRAININGS, TRAININGS_CHOOSE_MODULE } from "../../Routing/PageNames";
import { INPUT_TEXT, INPUT_TEXT_AREA } from "../GstaInput/GstaInput.constants";
//#endregion

//#region style import
import "./CreateCustomtrainingMainInformation.scss";
//#endregion

//#region constant import
import { TRANSLATIONS } from "../../Constants/Translations";
//#endregion

/**
 * component to create custom training
 */

const CreateCustomTrainingMainInformation = () => {
  const location = useLocation();
  const trainingToDuplicate = location.state?.training;
  //#region state
  const [createTrainingFormData, setCreateTrainingFormData] = useState({
    machineId: null,
    name: "",
    description: "",
  });
  const [machineOptions, setMachinesOptions] = useState([]);
  const [machineAvailable, setMachinesAvailable] = useState([]);

  //#endregion

  //#region constants
  //#endregion

  //#region others use....
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const centerId = useSelector((state) => state.connexionSlice.trainer?.activeCenter?.id);
  const translations = useSelector((state) => state.translationSlice.translations);
  const trainings = useSelector((state) => state.trainingSlice.trainings);
  //#endregion

  //#region functions
  const onCancelButtonClick = () => {
    navigate(TRAININGS.url);
  };
  const onNextButtonClick = () => {
    const trainingNameAlreadyExist = trainings.filter(
      (training) =>
        training.trainingName === createTrainingFormData.name && training.machineId === createTrainingFormData.machineId
    );
    if (trainingNameAlreadyExist.length > 0) {
      dispatch(
        setErrorNotification({
          message: getTranslations("personnalized_training_error_name", translations),
        })
      );
    } else if (createTrainingFormData.name.length > CREATE_CUSTOM_FIELD_NAME_MAX_LENGTH) {
      dispatch(
        setErrorNotification({
          message: getTranslations("personnalized_training_error_caracters", translations),
        })
      );
    } else {
      navigate(TRAININGS_CHOOSE_MODULE.url, {
        state: {
          training: { ...createTrainingFormData },
          machine: machineAvailable.find((machine) => machine.id === createTrainingFormData.machineId),
        },
      });
    }
  };

  const disableNextButton = () => {
    return (
      (createTrainingFormData.machineId === null && createTrainingFormData.name === "") ||
      createTrainingFormData.name === "" ||
      createTrainingFormData.machineId === null
    );
  };

  const onQuerySucess = (response) => {
    const processedData = response.map((machine) => ({
      value: machine.id,
      label: getTranslations(machine.languageKey, translations),
      icon: `icon-${machine.code}`,
    }));
    setMachinesAvailable(response);
    setMachinesOptions(processedData);
  };

  const handleCreateTrainingFormData = (propertyToModify, value) => {
    let createTrainingFormDataCopied = { ...createTrainingFormData };
    createTrainingFormDataCopied[propertyToModify] = value;
    setCreateTrainingFormData(createTrainingFormDataCopied);
  };

  //#endregion

  //#region useQuery
  useQuery({
    queryKey: ["machines"],
    queryFn: async () => {
      const machines = await getMachines(centerId);
      onQuerySucess(machines);
    },
    refetchOnWindowFocus: false,
  });

  useQuery({
    queryKey: ["trainingToDuplicate"],
    queryFn: async () => {
      try {
        const training = await getTrainingToDuplicate(centerId, trainingToDuplicate.id);
        setCreateTrainingFormData({ ...training, trainingName: training.name });
      } catch (error) {
        dispatch(setErrorNotification(error));
      }
    },
    refetchOnWindowFocus: false,
    enabled: trainingToDuplicate !== undefined,
  });
  //#endregion

  //#region useEffect
  //#endregion

  return (
    <section className="create-custom-training-main-information">
      <h2>{getTranslations("student_create_student_general_informations", translations)}</h2>
      <div>
        <span>
          <b>{getTranslations("common_training_name", translations)} *</b>
        </span>
        <span>{getTranslations(TRANSLATIONS.REQUIRED_FIELD, translations)}</span>
      </div>
      <GstaInput
        dataTestId={SetDataTestId("input-training-name")}
        type={INPUT_TEXT}
        isValid
        placeholder={getTranslations("personnalized_training_name_example", translations)}
        appearanceClass={"training-name"}
        value={getTranslations(createTrainingFormData.name, translations)}
        setValue={(value) => handleCreateTrainingFormData("name", value)}
      />
      <GstaInput
        dataTestId={SetDataTestId("input-training-description")}
        type={INPUT_TEXT_AREA}
        isValid
        label={getTranslations("personnalized_training_description", translations)}
        placeholder={getTranslations("personnalized_training_description_example", translations)}
        appearanceClass={"training-description"}
        value={getTranslations(createTrainingFormData.description, translations)}
        setValue={(value) => handleCreateTrainingFormData("description", value)}
      />
      <AcreosSelect
        dataTestId={SetDataTestId("select-machine")}
        title={`${getTranslations("personnalized_training_machine", translations)} *`}
        options={machineOptions}
        addClassCustom={"machine"}
        isValid
        defaultText={getTranslations("personnalized_training_choose_machine", translations)}
        value={createTrainingFormData.machineId}
        setNewValue={(value) => handleCreateTrainingFormData("machineId", value)}
      />
      <CreateValueFooter
        onCancelButtonClick={onCancelButtonClick}
        onNextButtonClick={onNextButtonClick}
        validateRender={false}
        disabled={disableNextButton()}
      />
    </section>
  );
};
export default CreateCustomTrainingMainInformation;
