//#region react import
import { useRef } from "react";
import { createPortal } from "react-dom";
//#endregion

//#region functions import
import useClickOutside from "../../Helper/CustomHook/useClickOutside";
//#endregion

//#region components import
import Loader from "../Loader/Loader.component";
//#endregion

//#region style import
import "./GstaModal.style.scss";
//#endregion

const GstaModal = ({
  modalOpen,
  handleCloseModal,
  handleValidate,
  title,
  iconTitle,
  children,
  cancelText,
  validText,
  FooterAddedComponent,
  FooterAddedComponentProps,
  validateDisabled,
  hasTableInside,
  className,
  loading,
  loadingText,
  disableUseClickOutside,
  validIcon,
  deleteIcon,
  overrideStyle
}) => {
  //#region constants
  //#endregion

  //#region others use...
  const ref = useRef();
  useClickOutside(disableUseClickOutside ? [] : [ref], modalOpen, handleCloseModal);
  //#endregion

  //#region functions
  const getCancelButtonStyle = () => {
    if (!cancelText) return { display: "none" };
    else return { visibility: "visible" };
  };

  //#endregion

  //#region useQuery
  //#endregion

  //#region useEffect
  //#endregion
  return (
    <>
      {modalOpen &&
        createPortal(
          <div className={"modal " + className}>
            <div
              className={hasTableInside ? "modal-content-table" : "modal-content"}
              ref={ref}
              isloading={loading?.toString()}
              style={overrideStyle}
            >
              <div className="modal-title">
                {iconTitle && <i className={iconTitle} />}
                <h1>{title}</h1>
              </div>
              {loading ? (
                <div className="modal-loader">
                  <Loader loadingText={loadingText} />
                </div>
              ) : (
                <div className={"modal-main"}>{children}</div>
              )}
              {!loading && (
                <div className="modal-action">
                  <>
                    <button
                      style={getCancelButtonStyle()}
                      className={"modal-action-button-cancel"}
                      onClick={handleCloseModal}
                      data-testid={"modal-cancel"}
                    >
                      {deleteIcon ? <i className={deleteIcon} /> : <i className="icon-undo" />}
                      <span>{cancelText}</span>
                    </button>
                    {FooterAddedComponent && (
                      <FooterAddedComponent FooterAddedComponentProps={FooterAddedComponentProps} />
                    )}
                    <button
                      className={"modal-action-button-validate"}
                      onClick={handleValidate}
                      data-testid={"modal-validate"}
                      disabled={validateDisabled}
                    >
                      <span>{validText}</span>
                      {validIcon ? <i className={validIcon} /> : <i className="icon-check" />}
                    </button>
                  </>
                </div>
              )}
            </div>
          </div>,
          document.getElementById("modal-root")
        )}
    </>
  );
};

export default GstaModal;
