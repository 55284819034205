//#region react import
import React, { useEffect, useRef } from "react";
//#endregion

//#region functions import
//#endregion

//#region components import
//#endregion

//#region services import
//#endregion

//#region constants import
import { INPUT_TEXT, INPUT_TEXT_AREA } from "./GstaInput.constants";
//#endregion

//#region style import
import "./GstaInput.style.scss";
//#endregion

/**
 * Generic input of gsta pro
 */

const GstaInput = ({
  label,
  id,
  value,
  setValue,
  isValid,
  placeholder,
  dataTestId,
  Icon,
  errorMessage,
  scrollTarget,
  appearanceClass,
  formatValue,
  type,
  autoFocus,
  clearable,
}) => {
  //#region constants
  let timeout;
  //#endregion

  //#region others use...
  const inputRef = useRef();
  //#endregion

  //#region functions
  const handleChange = (event) => {
    inputRef.current.value = formatValue ? formatValue(event.target.value) : event.target.value;
    clearTimeout(timeout);
    timeout = setTimeout(() => setValue(event.target.value), 800);
  };

  const focusInput = () => {
    inputRef.current.focus();
  };

  const getInput = () => {
    if (type === INPUT_TEXT)
      return (
        <input
          defaultValue={value}
          ref={inputRef}
          id={id}
          type="text"
          onChange={handleChange}
          placeholder={placeholder}
          data-testid={dataTestId}
          invalid={(!isValid).toString()}
          onBlur={(event) => {
            clearTimeout(timeout);
            setValue(event.target.value);
          }}
          onKeyDown={(event) => event.key === "Enter" && setValue(event.target.value)}
          autoComplete="off"
          autoFocus={autoFocus}
        />
      );
    else if (type === INPUT_TEXT_AREA)
      return (
        <textarea
          ref={inputRef}
          defaultValue={value}
          id={id}
          onChange={handleChange}
          placeholder={placeholder}
          data-testid={dataTestId}
          invalid={(!isValid).toString()}
          onBlur={(event) => {
            clearTimeout(timeout);
            setValue(event.target.value);
          }}
          onKeyDown={(event) => event.key === "Enter" && setValue(event.target.value)}
          autoComplete="off"
          autoFocus={autoFocus}
        />
      );
  };

  const resetValue = () => {
    inputRef.current.value = "";
    setValue("");
  };

  const getIconToRender = () => {
    if (clearable && inputRef.current?.value.length > 0)
      return (
        <i
          className={"icon-close"}
          onClick={resetValue}
        />
      );
    else if (Icon)
      return (
        <i
          className={Icon}
          onClick={focusInput}
        />
      );
  };
  //#endregion
  //#region useEffect
  useEffect(() => {
    if (scrollTarget && scrollTarget?.id === id) {
      inputRef.current?.scrollIntoView &&
        inputRef.current.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
    }
  }, [scrollTarget, id]);

  useEffect(() => {
    inputRef.current.value = value;
  }, [value]);
  //#endregion

  return (
    <section className={"gsta-input " + (appearanceClass ?? "")}>
      <div>
        {label && (
          <label htmlFor={label}>
            <b>{label}</b>
          </label>
        )}
        <article>
          {getInput()}
          {getIconToRender()}
        </article>
      </div>
      {errorMessage && <span className="full-width gsta-input-error-message">{!isValid ? errorMessage : " "}</span>}
    </section>
  );
};
export default GstaInput;
