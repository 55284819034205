export const TRANSLATIONS = {
  STUDENT_DETAIL_TRAINING_MACHINE_TOTAL_TIME_ELAPSED: "student_detail_training_machine_total_time_elapsed",
  CURSUS_MOD: "cursus_mod",
  FREE_MODE: "free_mode",
  STUDENT_DETAIL_TRAINING_MACHINE_AVERAGE: "student_detail_training_machine_average",
  STUDENT_DETAIL_TRAINING_SUBSCRIPTIONS: "student_detail_training_subscriptions",
  RESULT_DETAIL_NOT_STARTED: "result_detail_not_started",
  RESULT_DETAIL_NOT_STARTED_TAG: "result_detail_not_started_tag",
  STUDENT_DETAIL_TRAINING_AVERAGE: "student_detail_training_training_average",
  PDF_RESULT_PER_MACHINE_PROGRESS: "pdf_result_per_machine_progress",
  ARCHIVED_TRAINING: "archived_training",
  STUDENT_DETAIL_IN_PROGRESS: "student_detail_in_progress",
  STUDENT_DETAIL_FINISHED: "student_detail_finished",
  STUDENT_DETAIL_TRAINING_TIME: "student_detail_training_time",
  STUDENT_DETAIL_AVERAGE: "student_detail_average",
  STUDENT_DETAIL_TRAINING_PROGRESSION: "student_detail_training_progression",
  STUDENT_DETAIL_NO_TRAINING_INSCRIPTION: "student_detail_no_training_inscription",
  STUDENT_DETAIL_TRAINING: "student_detail_training",
  STUDENT_PAGINATION_ON: "student_pagination_on",
  STUDENT_PAGINATION_PAGE: "student_pagination_page",
  STUDENT_ALL_STUDENTS: "student_all_students",
  STUDENT_ADD_STUDENTS: "student_add_students",
  LINK_ARCHIVED_STUDENT: "link_archived_student",
  STUDENT_FIND_STUDENT_PLACEHOLDER: "student_find_student_placeholder",
  STUDENT_FIND_STUDENT_NO_RESULT: "student_find_student_no_result",
  COMMON_DEFAULT_TIME: "common_default_time",
  SIMULATION_PLANNING: "simulation_planning",
  OF_SIMULATION: "of_simulation",
  PERSONNALIZED_FIELD_DATE: "personnalized_field_date",
  FREE_TIME_MOD: "free_time_mod",
  TOTAL: "total",
  ERROR_GET_MONTHLY_ACTIVITY: "error_get_monthly_activity",
  HOME_LOADING: "home_loading",
  HOME_SIMULATOR_ACTIVITY: "home_simulator_activity",
  HOME_MONTH_ACTIVITY_HISTORY: "home_month_activity_history",
  HOME_MONTH_ACTIVITY_HISTORY_INFORMATIONS: "home_month_activity_history_informations",
  HOME_STUDENT_CONNECTED: "home_student_connected",
  HOME_STUDENT_SIMULATOR_TIME_AVERAGE: "home_student_simulator_time_average",
  HOME_STUDENT_SIMULATOR_TIME_AVERAGE_NO_STUDENT: "home_student_simulator_time_average_no_student",
  HOME_SIMULATOR_INACTIVITY: "home_simulator_inactivity",
  CURSUS_TIME: "cursus_time",
  IMPROVEMENT_IDEA: "improvement_idea",
  QUESTIONNARY_YOUR_NAME: "questionnary_your_name",
  QUESTIONNARY_SOFTWARE: "questionnary_software",
  QUESTIONNARY_DESCRIPTION: "questionnary_description",
  QUESTIONNARY_YOUR_NAME_PLACEHOLDER: "questionnary_your_name_placeholder",
  QUESTIONNARY_SOFTWARE_PLACEHOLDER: "questionnary_software_placeholder",
  QUESTIONNARY_DESCRIPTION_PLACEHOLDER: "questionnary_description_placeholder",
  QUESTIONNARY_INFO_HOTLINE: "questionnary_info_hotline",
  QUESTIONNARY_FEEDBACK_MODAL_TITLE: "questionnary_feedback_modal_title",
  QUESTIONNARY_ADD_NEW: "questionnary_add_new",
  QUESTIONNARY_SOFTWARE_FORMAT_ERROR: "questionnary_software_format_error",
  QUESTIONNARY_TRAINER_NAME_FORMAT_ERROR: "questionnary_trainer_name_format_error",
  QUESTIONNARY_SEND_ERROR: "questionnary_send_error",
  QUESTIONNARY_SEND_SUCCESS: "questionnary_send_success",
  REQUIRED_FIELD: "required_field",
  COMMON_SEND: "common_send",
  NOTIFICATION_CONNEXION: "notification_connexion",
  NOTIFICATION_ADD_STUDENT: "notification_add_student",
  NOTIFICATION_STUDENT_INSCRIPTION: "notification_student_inscription",
  ERROR_NO_INTERNET_FEATURE_DISABLED: "error_no_internet_feature_disabled",
  FIELD_IS_REQUIRED: "field_is_required",
  PERSONALIZED_FIELD_LIMIT_CARACTERS: "personnalized_field_limit_caracters",
  STUDENT_CREATE_STUDENT_GENERAL_INFORMATIONS: "student_create_student_general_informations",
  SENTENCE_MINIMUM_LENGTH: "sentence_minimum_length",
  PERSONALIZED_FIELD_CONFIRM_EXEMPLE: "personnalized_field_confirm_exemple",
  PERSONALIZED_FIELD_GROUPED_ACTION_CHOOSE: "personnalized_field_grouped_action_choose",
  STUDENT_CREATE_STUDENT_NAME: "student_create_student_name",
  STUDENT_CREATE_STUDENT_FIRST_NAME: "student_create_student_first_name",
  STUDENT_CREATE_STUDENT_PIN: "student_create_student_pin",
  STUDENT_CREATE_STUDENT_PIN_PLACEHOLDER: "student_create_student_pin_placeholder",
  STUDENT_CREATE_STUDENT_PIN_ERROR_MESSAGE: "student_create_student_pin_error_message",
  STUDENT_CREATE_STUDENT : "student_create_student",
  STUDENT_CREATE_STUDENT_VALIDATE_AND_CREATE_STUDENT: "student_create_student_validate_and_create_student",
  STUDENT_DETAIL_BACK_STUDENT_LIST_ARCHIVED: "student_detail_back_student_list_archived",
  STUDENT_DETAIL_BACK_STUDENT_LIST: "student_detail_back_student_list",
  STUDENT_DETAIL_SIMULATOR_ACCESS: "student_detail_simulator_access",
  STUDENT_DETAIL_RESULT_EXPORT: "student_detail_result_export",
  STUDENT_DETAIL_INSCRIPTION_MANAGE: "student_detail_inscription_manage",
  STUDENT_CREATE_STUDENT_ADD_STUDENT: "student_create_student_add_student",
  SESSION_DATE_STUDENT_PROFILE: "session_date_student_profile",
  SESSION_DATE_STUDENT_START: "session_date_student_start",
  SESSION_DATE_STUDENT_END: "session_date_student_end",
  SESSION_DATE_TO: "session_date_to",
  STEP_PROGRESS_STEP: "step_progress_step",
  SHOW_WORK_SKILLS: "show_work_skills",
  SHOW_STEP_DETAIL: "show_step_detail",
  SHOW_TRAINING_STEP_DETAIL: "show_training_step_detail",
  COMMON_RESULTS: "common_results",
  COMMON_YES: "common_yes",
  COMMON_NO: "common_no",
  COMMON_FIRST_NAME: "common_first_name",
  COMMON_NAME: "common_name",
  COMMON_NAME_LOWER: "common_name_lower",
  RESULT_DETAIL_CRITERION: "result_detail_criterions",
  RESULT_DETAIL_VALIDATE: "result_detail_validate",
  RESULT_RESULT_AVERAGE: "result_average_result",
  ERROR_GENERATE_PDF:"error_generate_pdf",
  PDF_DRIVER_PROFILE_NO_TITLE:"pdf_driver_profile_no_title",
  PDF_RESULT_SKILL_GENERIC:"pdf_result_skill_generic",
  SKILL_NOT_EVALUATED: "skill_not_evaluated",
  EXPORT_USED_BY_DEFAULT:"export_used_by_default",
  MODEL_NAME:"model_name",
  TRAINING_LAYOUT_DETAIL_GENERAL_INFORMATIONS:"training_layout_detail_general_informations",
  SUMMARY:"summary",
  SHOW_GENERIC_SKILLS:"show_generic_skills",
  SHOW_CONNEXION_CALENDAR:"show_connexion_calendar",
  SESSION_DATES:"session_dates",
  SINCE_STUDENT_CREATION:"since_student_creation",
  CUSTOM_DATES: "custom_dates",
  DATE_FOR_RESULTS: "date_for_results",
  RESULT_TARGET:"result_target",
  EDIT_CUSTOM_PDF_TEMPLATE: "eddit_custom_pdf_template",
  ADD_CUSTOM_PDF_TEMPLATE:"add_custom_template",
  SUCCESS_CREATE_PDF_TEMPLATE: "success_create_pdf_template",
  SUCCESS_UPDATE_PDF_TEMPLATE:"success_update_pdf_template",
  ERROR_UPDATE_PDF_TEMPLATE:"error_update_pdf_template",
  COMMON_CANCEL:"common_cancel",
  NEXT_BUTTON:"next_button",
  COMMON_VALIDATE:"common_validate",
  IMPORT_STUDENT_MODAL_GO_BACK:"import_student_modal_go_back",
  PLACEHOLDER_PDF_TEMPLATE_NAME:"placeholder_pdf_template_name",
  ERROR_PDF_TEMPLATE_NAME:"error_pdf_template_name",
  DEFAULT_USE:"default_use",
  EXCEL_IMPORT_POPUP_NO_FILE_SELECTED: "excel_import_popup_no_file_selected", 
  EXCEL_IMPORT_SUCCESS_MULTIPLE: "excel_import_success_multiple",
  EXCEL_IMPORT_ERROR_FILE: "excel_import_error_file",
  EXCEL_IMPORT_IN_PROGRESS: "excel_import_in_progress",
  EXCEL_IMPORT_ERROR_DUPLICATE_ENTRY: "excel_import_error_duplicate_entry",
  EXCEL_MISSING_ATTRIBUTION: "excel_missing_attribution",
  EXCEL_IMPORT_POPUP_MANDATORY_WARNING: "excel_import_popup_mandatory_warning",
  END_SESSION_DATE: "end_session_date",
  START_SESSION_DATE: "start_session_date",
  SESSION_DATES_ERROR: "session_dates_error",
  ADD_TRAINING_MODAL_STUDENT_TRAINING_INSCRIPTION: "add_training_modal_student_training_inscription",
  COLLABORATION_OBSERV_BUTTON: "collaboration_observ_button",
  OBSERV_SESSION_WARN_LAUNCH: "observ_session_warn_launch",
  CONTINUE_CONFIRM: "continue_confirm",
  COLLABORATION_SESSION: "collaboration_session",
  COLLABORATION_OBSERV_STUDENTS_CONNECT: "collaboration_observ_students_connect",
  COLLABORATION_OBSERV_CLICK_INFO: "collaboration_observ_click_info",
  EXCEL_IMPORT_BUTTON_IMPORT: "excel_import_button_import",
  LIGN_LENGHT_ERROR:"lign_lenght_error",
  LIGN:"lign"
};