//#region react import
import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
//#endregion

//#region component import
import ArchivedUnarcivedStudent from "../../../Components/ArchivedUnarchivedStudent/ArchivedUnarchivedStudent";
import ExportPdfOptions from "../../../Components/ExportPdfOptions/ExportPdfOptions.component";
import ExportPdfParametersModal from "../../../Components/ExportPdfParametersModal/ExportPdfParametersModal.component";
import GstaToolTip from "../../../Components/GstaToolTip/GstaToolTip.component";
import AddTrainingModal from "../../Training/AddTrainingModal/AddTrainingModal.component";
//#endregion

//#region store import
import { setErrorNotification, setSuccessNotification } from "../../../ReduxStore/notificationSlice";
//#endregion

//#region services import
import { PrepareCustomPdf } from "../../../Helper/ExportPdf";
import { getStudentResultsByTemplate } from "../../../Services/StudentService";
//#endregion

//#region functions import
import { copyObject } from "../../../Helper/CopyObject";
import useClickOutside from "../../../Helper/CustomHook/useClickOutside";
import { getNotificationMessage } from "../../../Helper/ExportPdf";
import { DisableOverflow, EnableOverflow } from "../../../Helper/Overflow";
import { getTranslations } from "../../../Helper/TranslationController";
import { getEndPeriod, getStartPeriod } from "../Student/Student.constants";
//#endregion

//#region store import
import { setTrainings } from "../../../ReduxStore/trainingSlice";
//#endregion

//#region services import
import {
  closeStudentSubscription,
  createStudentSubscription,
  getStudentSubscriptions,
  studentResubscription,
} from "../../../Services/SubscriptionsService";
//#endregion

//#region constants import
import { STUDENT_INSCRIPTION } from "../../../Constants/AddtrainingModalType";
import { ICON_NOTE_ADD } from "../../../Constants/IconConstants";
import { MANAGE_STUDENTS_ACCESS_WITH_DATES } from "../../../Constants/SettingsConstants";
//#endregion

//#region style import
import { TRANSLATIONS } from "../../../Constants/Translations";
import "./StudentActions.style.scss";
//#endregion

const StudentActions = ({ value, ActionsProps, setRowOpened }) => {
  //#region state
  const [addTrainingModalOpen, setAddtrainingModalOpen] = useState(false);
  const [selectedTrainingToSubscribe, setSelectedTrainingToSubscribe] = useState(false);
  const [trainingsToUnsubscribe, setTrainingsToUnsubscribe] = useState([]);
  const [trainingsToResubscribe, setTrainingsToResubscribe] = useState([]);
  const [subscriptions, setSubscriptions] = useState([]);
  const [exportPdfParametersModalOpen, setExportPdfParametersModalOpen] = useState(false);
  const [showExportPdfOptions, setShowExportPdfOptions] = useState(false);
  //#endregion
  //#region redux store
  const centerId = useSelector((state) => state.connexionSlice.trainer?.activeCenter?.id);
  const centerName = useSelector((state) => state.connexionSlice.trainer?.activeCenter?.name);
  const translations = useSelector((state) => state.translationSlice.translations);
  const notationType = useSelector((state) => state.settingSlice.notationType);
  const customFields = useSelector((state) => state.studentSlice.customFields);
  const sessionDates = useSelector((state) => state.settingSlice.settingDictionary[MANAGE_STUDENTS_ACCESS_WITH_DATES]);
  const trainings = useSelector((state) => state.trainingSlice.trainings);
  //#endregion

  //#region other use
  const dispatch = useDispatch();
  const refExportPdfOptions = useRef();
  //#endregion

  //#region functions
  const handleCloseTrainingModal = () => {
    setAddtrainingModalOpen(false);
  };
  const handleOpenModal = async () => {
    try {
      setAddtrainingModalOpen(!addTrainingModalOpen);
      DisableOverflow();
      if (!addTrainingModalOpen) {
        setRowOpened();
        setSelectedTrainingToSubscribe([]);
        setTrainingsToUnsubscribe([]);
        setTrainingsToResubscribe([]);
        const studentSubscriptions = await getStudentSubscriptions(centerId, value.id);
        setSubscriptions(studentSubscriptions);
      }
    } catch (e) {
      EnableOverflow();
      dispatch(setErrorNotification(e));
    }
  };

  const handleValidate = async (studentId) => {
    let trainingsCopied = copyObject(trainings);
    try {
      if (selectedTrainingToSubscribe.length > 0) {
        selectedTrainingToSubscribe.forEach((trainingId) => {
          if (trainingsCopied.some((training) => training.id === trainingId)) {
            trainingsCopied.find((training) => training.id === trainingId).studentCount =
              trainingsCopied.find((training) => training.id === trainingId).studentCount + 1;
          }
        });
        await createStudentSubscription(
          centerId,
          selectedTrainingToSubscribe.map((trainingId) => ({
            studentId: studentId,
            trainingId: trainingId,
          }))
        );
      }

      if (trainingsToUnsubscribe.length > 0) {
        trainingsToUnsubscribe.forEach((subscriptionId) => {
          const subscription = subscriptions.find((subscription) => subscription.subscriptionId === subscriptionId);
          if (trainingsCopied.some((training) => training.id === subscription.trainingId)) {
            trainingsCopied.find((training) => training.id === subscription.trainingId).studentCount =
              trainingsCopied.find((training) => training.id === subscription.trainingId).studentCount - 1;
          }
        });
        await closeStudentSubscription(centerId, trainingsToUnsubscribe);
      }

      if (trainingsToResubscribe.length > 0) {
        await studentResubscription(centerId, trainingsToResubscribe);
      }
      dispatch(setTrainings(trainingsCopied));
      dispatch(setSuccessNotification("success_notification_subscriptions_update"));
    } catch (e) {
      dispatch(setErrorNotification(e));
    }
  };

  const generateCustomPdfDocument = async () => {
    ActionsProps.setIsLoading(true);
    let personalizedStartDateCloned;
    let personalizedEndDateCloned;
    if (!exportPdfParametersModalOpen) {
      personalizedStartDateCloned = null;
      personalizedEndDateCloned = null;
    } else {
      personalizedStartDateCloned = ActionsProps.personalizedStartDate;
      personalizedEndDateCloned = ActionsProps.personalizedEndDate;
    }
    try {
      const studentResult = await getStudentResultsByTemplate(centerId, value.id, {
        templateId: ActionsProps.selectedPdfTemplate.id,
        startPeriod: getStartPeriod(value, sessionDates, ActionsProps.selectedPeriod, personalizedStartDateCloned),
        endPeriod: getEndPeriod(value, sessionDates, ActionsProps.selectedPeriod, personalizedEndDateCloned),
      });
      await PrepareCustomPdf(
        notationType,
        translations,
        value.accessStart,
        value.accessEnd,
        sessionDates,
        customFields,
        ActionsProps.selectedPdfTemplate,
        {
          ...studentResult,
          customValues: value.customValues,
          firstName: value.firstName,
          lastName: value.name,
          center: centerName,
        },
        centerId,
        ActionsProps.setIsLoading,
        () => dispatch(setSuccessNotification(getNotificationMessage(value, translations))),
        () => dispatch(setErrorNotification(getTranslations(TRANSLATIONS.ERROR_GENERATE_PDF, translations)))
      );
    } catch (e) {
      dispatch(setErrorNotification(e));
    }
    setExportPdfParametersModalOpen(false);
  };

  useClickOutside([refExportPdfOptions], true, () => setShowExportPdfOptions(false));
  //#endregion
  return (
    <>
      {addTrainingModalOpen && (
        <AddTrainingModal
          modalOpen={addTrainingModalOpen}
          setModalOpen={setAddtrainingModalOpen}
          handleModalClose={handleCloseTrainingModal}
          selectedTrainingToSubscribe={selectedTrainingToSubscribe}
          setSelectedTrainingToSubscribe={setSelectedTrainingToSubscribe}
          selectedTrainingToReSubscribe={trainingsToResubscribe}
          setSelectedTrainingToReSubscribe={setTrainingsToResubscribe}
          selectedTrainingToUnsubscribe={trainingsToUnsubscribe}
          setSelectedTrainingToUnsubscribe={setTrainingsToUnsubscribe}
          subscriptions={subscriptions}
          setTrainingSubscribed={setSubscriptions}
          validateAction={handleValidate}
          setValues={ActionsProps.setValues}
          type={STUDENT_INSCRIPTION}
          students={[value]}
          title={`${getTranslations("add_training_modal_student_training_inscription", translations)} ${
            value.firstName
          } ${value.name}`}
        />
      )}
      <div className="student-actions-container">
        <GstaToolTip toolTipContent={getTranslations("student_grouped_actions_inscription", translations)}>
          <button
            className="gsta-table-action-button"
            onClick={handleOpenModal}
          >
            <i className={ICON_NOTE_ADD} />
          </button>
        </GstaToolTip>

        <GstaToolTip toolTipContent={getTranslations("student_generate_pdf", translations)}>
          <button
            className="gsta-table-action-button"
            onClick={() => setShowExportPdfOptions(!showExportPdfOptions)}
            disabled={ActionsProps.isLoading}
          >
            <i className="icon-pdf" />
          </button>

          <ExportPdfParametersModal
            modalOpen={exportPdfParametersModalOpen}
            setModalOpen={setExportPdfParametersModalOpen}
            generateCustomPdfDocument={generateCustomPdfDocument}
            pdfTemplates={ActionsProps.pdfTemplates}
            setPdfTemplates={ActionsProps.setPdfTemplates}
            selectedPeriod={ActionsProps.selectedPeriod}
            setSelectedPeriod={ActionsProps.setSelectedPeriod}
            selectedPdfTemplate={ActionsProps.selectedPdfTemplate}
            setSelectedPdfTemplate={ActionsProps.setSelectedPdfTemplate}
            personalizedStartDate={ActionsProps.personalizedStartDate}
            setPersonalizedStartDate={ActionsProps.setPersonalizedStartDate}
            personalizedEndDate={ActionsProps.personalizedEndDate}
            setPersonalizedEndDate={ActionsProps.setPersonalizedEndDate}
          />
        </GstaToolTip>
        {showExportPdfOptions && (
          <ExportPdfOptions
            refExportPdfOptions={refExportPdfOptions}
            setModalOpen={setExportPdfParametersModalOpen}
            generateCustomPdfDocument={generateCustomPdfDocument}
          />
        )}
        <ArchivedUnarcivedStudent
          archive
          student={value}
          deleteArchivesStudent={ActionsProps.deleteStudent}
        />
      </div>
    </>
  );
};

export default StudentActions;
