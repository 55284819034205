//#region react import
import React, { useState } from "react";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
//#endregion

//#region functions import
import { hasTwoWords, isNullOrSpaces } from "../../Helper/StringUtils";
import { getTranslations } from "../../Helper/TranslationController";
//#endregion

//#region component import
import GstaModal from "../GstaModal/GstaModal.component";
import ModalQuestionnaryFooterContent from "./ModalQuestionnaryFooterContent.component";
import QuestionnaryModalContent from "./QuestionnaryModalContent.component";
//#endregion

//#region store import
import { setErrorNotification, setSuccessNotification } from "../../ReduxStore/notificationSlice";
//#endregion

//#region constants import
import { TRANSLATIONS } from "../../Constants/Translations";
//#endregion

//#region service import
import { hasOnlineConnection, isLocalInstallation } from "../../Services/ApiServerService";
import { postRex } from "../../Services/CustomerService";
import { FIREFOX_VERSIONS } from "../../Constants/BrowserConstants";
//#endregion

const QuestionnaryModal = ({ isModalOpen, closeModal }) => {
  //#region store
  const translations = useSelector((state) => state.translationSlice.translations);
  const dispatch = useDispatch();
  //#endregion

  //#region hook
  const trainer = useSelector((state) => state.connexionSlice.trainer);
  //#endregion

  //#region state
  const [isOnline, setIsOnline] = useState();
  const [isLocal, setIsLocal] = useState();
  const [isAlreadySended, setIsAlreadySended] = useState(false);
  const [isCreateNew, setIsCreateNew] = useState(false);
  const [trainerName, setTrainerName] = useState(!isLocal ? "" : `${trainer?.lastName} ${trainer?.firstName}`);
  const [softwares, setSoftwares] = useState("");
  const [description, setDescription] = useState("");
  const [isPostEnabled, setIsPostEnabled] = useState(false);
  //#endregion

  //#region useQuery
  useQuery({
    queryKey: ["isConnected"],
    queryFn: () => hasOnlineConnection(),
    refetchOnWindowFocus: false,
    meta: {
      onSuccess: (isConnected) => {
        setIsOnline(isConnected);
        if (!isConnected) {
          dispatch(
            setErrorNotification({
              message: getTranslations(TRANSLATIONS.ERROR_NO_INTERNET_FEATURE_DISABLED, translations),
            })
          );
          closeModal();
        }
      },
      errorMessage: TRANSLATIONS.NOTIFICATION_CONNEXION,
    },
  });

  useQuery({
    queryKey: ["isLocal"],
    queryFn: () => isLocalInstallation(),
    refetchOnWindowFocus: false,
    meta: {
      onSuccess: (isLocalInstallation) => {
        setIsLocal(isLocalInstallation);
      },
      errorMessage: TRANSLATIONS.NOTIFICATION_CONNEXION,
    },
  });

  useQuery({
    queryKey: ["postRex"],
    queryFn: () => postRex({ CustomerName: trainerName, SoftwareName: softwares, Content: description }),
    refetchOnWindowFocus: false,
    enabled: isPostEnabled,
    meta: {
      onSuccess: () => {
        dispatch(setSuccessNotification(getTranslations(TRANSLATIONS.QUESTIONNARY_SEND_SUCCESS, TRANSLATIONS)));
        if (isCreateNew) {
          createNewForm();
        } else {
          closeModal();
        }
      },
      errorMessage: TRANSLATIONS.QUESTIONNARY_SEND_ERROR,
    },
  });
  //#endregion

  //#region functions
  const handleCreateNew = () => {
    setIsCreateNew(!isCreateNew);
  };

  const isFormValid = () => {
    return (
      (isLocal ? !isNullOrSpaces(trainerName) && hasTwoWords(trainerName) : isNullOrSpaces(trainerName)) &&
      !isNullOrSpaces(softwares) &&
      !isNullOrSpaces(description) &&
      softwares.length > 2
    );
  };

  const validateForm = () => {
    setIsAlreadySended(true);
    if (isFormValid()) {
      setIsPostEnabled(true);
    }
  };

  const createNewForm = () => {
    setSoftwares("");
    setDescription("");
    setIsPostEnabled(false);
    setIsAlreadySended(false);
  };

  return (
    <section>
      {isOnline && (
        <GstaModal
          className={"questionnary-modal"}
          cancelText={getTranslations("common_cancel", translations)}
          validText={getTranslations(TRANSLATIONS.COMMON_SEND, translations)}
          title={getTranslations(TRANSLATIONS.QUESTIONNARY_FEEDBACK_MODAL_TITLE, translations)}
          modalOpen={isModalOpen}
          handleCloseModal={closeModal}
          handleValidate={validateForm}
          FooterAddedComponent={ModalQuestionnaryFooterContent}
          FooterAddedComponentProps={{
            isChecked: isCreateNew,
            handleCheck: handleCreateNew,
          }}
          overrideStyle={new RegExp(FIREFOX_VERSIONS).test(navigator.userAgent) ? { overflow: "hidden" } : {}}> 
          <QuestionnaryModalContent
            isLocal={isLocal}
            trainerName={trainerName}
            setTrainerName={setTrainerName}
            softwares={softwares}
            setSoftwares={setSoftwares}
            description={description}
            setDescription={setDescription}
            alreadySended={isAlreadySended}
          />
        </GstaModal>
      )}
    </section>
  );
};

export default QuestionnaryModal;
