export const NAVIGATION_BUTTON_ICON = "navigation-button-icon";
export const ICON_HOME = "icon-home";
export const ICON_CONVERSION_PATH = "icon-conversion_path";
export const ICON_LOCATION_ON = "icon-location_on";
export const TEXT_ICON = "text-icon";
export const ICON_LOGO_GSTA = "icon-Logo_GSTA";
export const ICON_LOGIN = "icon-login";
export const ICON_PERSON_ADD = "icon-person_add";
export const ICON_NOTE_ADD = "icon-note_add";
export const ICON_RESTART = "icon-restart";
export const ICON_EXPAND_MORE = "icon-expand_more";
export const ICON_HELP = "icon-help";
export const ICON_FEEDBACK = "icon-feedback";
export const PERSON = "person";
export const CONVERSION_PATH = "conversion_path"
export const GROUPS = "groups";
export const RED_CROSS = "redCross_pdf";
export const GREEN_MARK = "greenMark_pdf";
export const ICON_TROPHY = "icon-trophy";
export const ICON_PERSON = "icon-person";
export const HOME = "home"
export const LOCATION_ON = "location_on";
export const ICON_EAST = "icon-east";
export const ICON_CHECK = "icon-check";
export const ICON_ARROW_CIRCLE_LEFT = "icon-arrow_circle_left";
export const ICON_UNDO = "icon-undo";
export const ICON_GROUPS = "icon-groups";
export const ICON_PDF = "icon-pdf";
export const ICON_BUTTON = "icon-button";
export const ICON_KEYBOARD_ARROW_LEFT = "icon-keyboard_arrow_left";
export const ICON_KEYBOARD_DOUBLE_ARROW_LEFT = "icon-keyboard_double_arrow_left";
export const ICON_KEYBOARD_ARROW_RIGHT = "icon-keyboard_arrow_right";
export const ICON_KEYBOARD_DOUBLE_ARROW_RIGHT = "icon-keyboard_double_arrow_right";
export const ICON_UPLOAD_FILE = "icon-upload_file";
export const ICON_FLAG = "icon-flag"
