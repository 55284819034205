//#region react import
import React from "react";
//#endregion

//#region component import
import GlobalLayout from "../../../Layout/GlobalLayout/GlobalLayout.component";
import StudentPage from "../Student/Student.page";
//#endregion

//#region function import
import ScrollToTopController from "../../../Helper/CustomHook/ScrollToTopController";
//#endregion

//#region constants import
import { GROUPS } from "../../../Constants/IconConstants";
import { STUDENTS } from "../../../Routing/PageNames";
//#endregion

const StudentHome = () => {
  return (
    <GlobalLayout
      icon={GROUPS}
      title={STUDENTS.pageTraductionKey}
    >
      <ScrollToTopController />
      <StudentPage />
    </GlobalLayout>
  );
};

export default StudentHome;
