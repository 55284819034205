//#region constantes import
import { CUSTOM_FIELD, CUSTOM_FIELD_DATETIME, CUSTOM_FIELD_LIST } from "../../../Constants/CustomFieldType";
import { FILTERED, FILTERED_BY_DATE, ORDERED } from "../../../Constants/HeaderType";
import {
  MANAGE_STUDENTS_ACCESS_WITH_DATES,
  SHOW_STUDENTS_LAST_ACTIVITY,
  SHOW_STUDENTS_MACHINES,
} from "../../../Constants/SettingsConstants";
import { SELECTED_PERIOD } from "../../../Components/ExportPdfModal/ExportPdfModal.constants";
//#endregion

//#region functions import
import { DateTimeToIsoString } from "../../../Helper/TimeConverter";
import { getTranslations } from "../../../Helper/TranslationController";
//#endregion

//#region component import
import StudentAccessDate from "../StudentAccessDate/StudentAccessDate.component";
import StudentDate from "../StudentDate/StudentDate.component";
import StudentIsActiveCell from "../StudentIsActiveCell/StudentIsActiveCell.component";
import StudentName from "../StudentName/StudentName.component";
import StudentsMachines from "../StudentsMachines/StudentsMachines.component";
//#endregion


const getFirstNameLastName = (objectValue) => {
  return `${objectValue.name} ${objectValue.firstName}`;
};

const getAccessSimulator = (objectValue) => {
  return objectValue.isActive ? "common_yes" : "common_no";
};

const getMachines = (objectValue, translations) => {
  const machines = [];
  objectValue.machineSubscribes.forEach((element) => {
    machines.push(getTranslations(element.languageKey, translations));
  });
  return machines;
};

const getAccessDuration = (objectValue) => {
  return [objectValue.accessStart, objectValue.accessEnd];
};

const getCustomFieldValue = (student, customField) => {
  const valueToReturn =
    student?.customValues?.find((customValue) => customValue.name === customField.name)?.value ?? "";
  if (customField.fieldType === CUSTOM_FIELD_DATETIME.value && valueToReturn.length > 0) {
    return DateTimeToIsoString(new Date(valueToReturn));
  }
  return valueToReturn;
};

const getDate = (objectValue) => {
  return objectValue.lastConnectionDate ?? 0;
};

export const getHeaderDefinition = (
  translations,
  activeCenterId,
  settingDictionary,
  customFields,
  pdfTemplates,
  setPdfTemplates,
  selectedPdfTemplate,
  setSelectedPdfTemplate,
  selectedPeriod,
  setSelectedPeriod,
  personalizedStartDate,
  setPersonalizedStartDate,
  personalizedEndDate,
  setPersonalizedEndDate,
  showAccessToSimulator
) => {
  const headerDefinition = [
    {
      columnName: getTranslations("student_name", translations),
      type: ORDERED,
      getValue: getFirstNameLastName,
      overrideColumn: StudentName,
      expandedColumn: true,
      searchColumn: true,
      overrideProps: {
        pdfTemplates: pdfTemplates,
        setPdfTemplates: setPdfTemplates,
        selectedPdfTemplate: selectedPdfTemplate,
        setSelectedPdfTemplate: setSelectedPdfTemplate,
        selectedPeriod: selectedPeriod,
        setSelectedPeriod: setSelectedPeriod,
        personalizedStartDate: personalizedStartDate,
        setPersonalizedStartDate: setPersonalizedStartDate,
        personalizedEndDate: personalizedEndDate,
        setPersonalizedEndDate: setPersonalizedEndDate
      }
    },
  ];

  if (showAccessToSimulator) {
    if (settingDictionary[MANAGE_STUDENTS_ACCESS_WITH_DATES] === "true") {
      headerDefinition.push({
        columnName: getTranslations("student_simulator_access", translations),
        type: FILTERED_BY_DATE,
        getValue: getAccessDuration,
        overrideColumn: StudentAccessDate,
      });
    } else {
      headerDefinition.push({
        columnName: getTranslations("student_simulator_access", translations),
        type: FILTERED,
        getValue: (value) => getTranslations(getAccessSimulator(value), translations),
        overrideColumn: StudentIsActiveCell,
        overrideProps: {
          centerId: activeCenterId,
        },
      });
    }
  }

  if (settingDictionary[SHOW_STUDENTS_MACHINES] === "true") {
    headerDefinition.push({
      columnName: getTranslations("common_machine", translations),
      type: FILTERED,
      getValue: (objectValue) => getMachines(objectValue, translations),
      overrideColumn: StudentsMachines,
      searchColumn: true,
    });
  }
  customFields?.forEach((customField) => {
    if (customField?.isInGrid === true) {
      headerDefinition.push({
        columnName: customField.name,
        getValue: (student) => getCustomFieldValue(student, customField),
        type: customField.fieldType === CUSTOM_FIELD_LIST.value ? FILTERED : ORDERED,
        searchColumn: customField.fieldType === CUSTOM_FIELD.CUSTOM_FIELD_STRING.value ? true : false,
      });
    }
  });

  if (settingDictionary[SHOW_STUDENTS_LAST_ACTIVITY] === "true") {
    headerDefinition.push({
      columnName: getTranslations("student_last_activity", translations),
      getValue: getDate,
      overrideColumn: StudentDate,
      type: ORDERED,
      searchColumn: true,
    });
  }

  return headerDefinition;
};

export const getStartPeriod = (student, sessionDates, selectedPeriod, personalizedStartDate) => {
  if (sessionDates && selectedPeriod === SELECTED_PERIOD.SESSION_DATES) {
    return student.accessStart;
  } else if (personalizedStartDate && selectedPeriod === SELECTED_PERIOD.PERSONALIZED_DATES) {
    return personalizedStartDate;
  } else {
    return new Date(0);
  }
};

export const getEndPeriod = (student, sessionDates, selectedPeriod, personalizedEndDate) => {
  if (sessionDates && selectedPeriod === SELECTED_PERIOD.SESSION_DATES) {
    return student.accessEnd;
  } else if (personalizedEndDate && selectedPeriod === SELECTED_PERIOD.PERSONALIZED_DATES) {
    return personalizedEndDate;
  } else {
    return new Date();
  }
};
