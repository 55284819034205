//#region react import
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
//#endregion

//#region functions import
import { getTranslations } from "../../Helper/TranslationController";
//#endregion

//#region components import
//#endregion

//#region services import
//#endregion

//#region constants import
import { TRANSLATIONS } from "../../Constants/Translations";
//#endregion

/**
 * Basic component designed to be the footer of the student imported modal
 */

const ImportStudentsModalFooterOverride = ({ FooterAddedComponentProps }) => {
  //#region router
  //#endregion

  //#region constants
  //#endregion

  //#region others use...
  const translations = useSelector((state) => state.translationSlice.translations);
  //#endregion

  //#region functions

  const renderContentStep2 = () => {
    if (
      (Object.values(FooterAddedComponentProps.acreosSelectedValues).includes(null) ||
        Object.keys(FooterAddedComponentProps.acreosSelectedValues).length < FooterAddedComponentProps.columnNumber) &&
      FooterAddedComponentProps.customFieldMandatoryNumber === 0
    ) {
      return getTranslations(TRANSLATIONS.EXCEL_MISSING_ATTRIBUTION, translations);
    } else if (FooterAddedComponentProps.customFieldMandatoryNumber > 0) {
      return `${FooterAddedComponentProps.customFieldMandatoryNumber} ${getTranslations(
        TRANSLATIONS.EXCEL_IMPORT_POPUP_MANDATORY_WARNING,
        translations
      )}`;
    } else {
      return "";
    }
  };

  // TODO : Return error based on the custom fieldType

  // const renderContentStep3 = () => {
  //   let errorTypeStringEmptyNumber = 0;
  //   let errorTypeStringLengthNumber = 0;
  //   let errorTypeDateNumber = 0;
  //   let errorTypeListNumber = 0;

  //   FooterAddedComponentProps.dataError?.forEach((item) => {
  //     if (item.errorType === "string" && item.value === "") {
  //       errorTypeStringEmptyNumber++;
  //     } else if (item.errorType === "string" && item.value.length > 32) {
  //       errorTypeStringLengthNumber++;
  //     } else if (item.errorType === "date") {
  //       errorTypeDateNumber++;
  //     } else {
  //       errorTypeListNumber++;
  //     }
  //   });

  //   if (errorTypeStringEmptyNumber > 0) {
  //     return `${errorTypeStringEmptyNumber} ${getTranslations("excel_import_error_empty_ligne", translations)}`;
  //   } else if (errorTypeStringLengthNumber > 0) {
  //     return `${errorTypeStringLengthNumber} ${getTranslations("excel_import_error_max_cara", translations)}`;
  //   }
  // };

  //#endregion

  //#region useQuery
  //#endregion

  //#region useEffect
  //#endregion

  return <p>{FooterAddedComponentProps.contentToRender === 2 ? renderContentStep2() : ""}</p>;
};
ImportStudentsModalFooterOverride.propTypes = { FooterAddedComponentProps: PropTypes.any };
export default ImportStudentsModalFooterOverride;
