//#region react import
import { connect } from "react-redux";
import { Component } from "react";
//#endregion

//#region component import
import GstaToolTip from "../../../Components/GstaToolTip/GstaToolTip.component";
//#endregion

//#region function import
import { stringFormatter } from "../../../Helper/StringFormatter";
import { createDateFromISO, DateTimeToIsoString } from "../../../Helper/TimeConverter";
import { getTranslations } from "../../../Helper/TranslationController";
//#endregion

//#region constantes import
import { STANDARD_FORMAT_STRING_LENGTH } from "../../../Constants/StringConstants";
//#endregion

//#region style import
import "./StudentAccessDate.style.scss";
//#endregion

class StudentAccessDate extends Component {
  shouldComponentUpdate(nextProps) {
    return (
      this.props.value.accessStart !== nextProps.value.accessStart ||
      this.props.value.accessEnd !== nextProps.value.accessEnd
    );
  }

  render() {
    const { value, translations } = this.props;
    
    const start = createDateFromISO(value.accessStart);
    const end = createDateFromISO(value.accessEnd);
    
    const accessDate =
      DateTimeToIsoString(start) + " - " + DateTimeToIsoString(end);
    const accessAuthorised =
      new Date(start.getFullYear(), start.getMonth(), start.getDate(), 0, 0, 0, 0) <= new Date() &&
      new Date() <= new Date(end.getFullYear(), end.getMonth(), end.getDate(), 23, 59, 59, 59);
    const trainingAccessibility = accessAuthorised
      ? "session_date_session_inprogress"
      : "session_date_session_finished";
    const textClassName = accessAuthorised ? "access-dates-text-orange" : "access-dates-text-default";

    return (
      <GstaToolTip
        toolTipContent={getTranslations(trainingAccessibility, translations)}
        overrideClass={accessAuthorised ? "color-orange" : ""}
      >
        <p className={textClassName}>{stringFormatter(accessDate, STANDARD_FORMAT_STRING_LENGTH)}</p>
      </GstaToolTip>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    translations: state.translationSlice.translations,
  };
};
const mapDispatchToProps = (dispatch, ownProps) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(StudentAccessDate);
